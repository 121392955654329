<template>
    <div class="side-menu">
        <ul class="main-menu">
            <li>
                <h4 class="mb-0">總覽</h4>
                <ul class="sub-menu">
                    <li>
                        <router-link class="router-link" to="/">
                            <i class="align-middle fas fa-fw fa-tachometer-alt fs-19"></i> <span class="align-middle">儀表板</span>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <h4 class="mb-0">假牙訂單管理</h4>
                <ul class="sub-menu">
                    <li class="">
                        <router-link class="router-link" to="/order/list">
                            <i class="align-middle fas fa-fw fa-file-alt fs-19"></i> <span class="align-middle">訂單管理</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link class="router-link" to="/assign/list">
                            <i class="align-middle fas fa-fw fa-hand-point-right fs-19"></i> <span class="align-middle">指名</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link class="router-link" to="/match/list">
                            <i class="align-middle fas fa-fw fa-sitemap fs-19"></i> <span class="align-middle">媒合</span>
                        </router-link>
                    </li> -->
                </ul>
            </li>
            <li>
                <h4 class="mb-0">媒合管理</h4>
                <ul class="sub-menu">
                    <li class="">
                        <router-link class="router-link" to="/clinic/list">
                            <i class="align-middle far fa-fw fa-hospital fs-19"></i> <span class="align-middle">診所管理</span>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <h4 class="mb-0">系統管理</h4>
                <ul class="sub-menu">
                    <li class="">
                        <router-link class="router-link" to="/account/list">
                            <i class="align-middle fas fa-fw fa-users fs-19"></i> <span class="align-middle">帳號管理</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'sideMenu',
        props:[
            
        ],
        methods:{
            
        }
        
    }
</script>